import React from "react"
import { graphql } from "gatsby"
import Moment from "react-moment"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import ItemSingle from "../components/elements/item-single"
import CustomComponent from "../components/elements/custom-component"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useGroupedComponents } from "../hooks/use-grouped-components"

const AsmetNoticeTemplate = ({ data }) => {
  const notice = data.noticeSingle

  const asmetHome = useAsmetHome()
  const groupedComponents = useGroupedComponents(notice.contenido)

  const crumbs = [
    { path: `/noticias`, label: "Noticias" },
    { path: `/noticias/${notice.slug}`, label: notice.titulo },
  ]

  return (
    <Layout
      meta={
        notice.meta
          ? { ...notice.meta, og_tipo: "notice" }
          : {
              og_tipo: "notice",
              og_titulo: notice.titulo,
              og_descripcion: notice.descripcion || null,
              og_imagen: notice.imagen_principal || null,
            }
      }
    >
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">{notice.titulo}</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="asm-page-general">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 mb-5">
              <ItemSingle
                item={{
                  id: notice.strapiId,
                  titulo: notice.titulo,
                  descripcion: notice.descripcion,
                  imagen: notice.imagen_principal,
                }}
              />
            </div>
            <div className="col-12 col-sm-8 mb-n5">
              <p className="text-gray-beta text-right">
                <Moment format="LL">{notice.fecha}</Moment>
              </p>
            </div>
          </div>
        </div>

        {groupedComponents.map((item, index) => {
          return <CustomComponent component={item} index={index} key={index} pageId={notice.slug} />
        })}
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default AsmetNoticeTemplate

export const query = graphql`
  query NoticeSingle($id: String!) {
    noticeSingle: strapiMediaNoticia(strapiId: { eq: $id }) {
      strapiId
      slug
      titulo
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }
      fecha
      imagen_principal {
        publicURL
      }
      descripcion

      contenido {
        id
        tipo_componente

        # Component Texto

        contenido

        # Component Documento

        titulo
        fecha_publicacion
        es_descargable
        descripcion
        archivo {
          publicURL
          extension
        }

        # Component Banner

        slides {
          tipo_componente
          titulo
          subtitulo
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }

        # Component Card

        imagen {
          publicURL
        }
        link {
          nombre
          tipo
          link_id
          icono {
            publicURL
          }
        }

        # Component Grupo Cards

        cards {
          tipo_componente
          titulo
          descripcion
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }
      }
    }
  }
`
